/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import { Controller, type FieldValues, type UseFormHandleSubmit } from 'react-hook-form';
import { AiOutlineCloseCircle } from 'react-icons/ai';

// Contextos

// Hooks
import { useParentCategories, useCategories } from '../../hooks/useCategories';

// Componentes
import { actualDate, formatInputDate } from '../../helpers/Dates';
import Markdown from '../Markdown';

// Imagenes

// Estilos

// Types
import { type ThemeContextType } from '../../types/ThemeContext';

interface Props {
  errors: any;
  control: any;
  theme: ThemeContextType['theme'];
  register: any;
  updateOffer: any;
  handleClick: () => void;
  actualData: ActualDataType;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
}

interface ActualDataType {
  id: number;
  title: string;
  short_description: string;
  content: string;
  end_date: string;
  active: boolean;
  categories?: Array<{ id: number; name: string; parent: string }>;
}

interface CategoryOption {
  value: number;
  label: string;
}

interface GroupedCategoryOption {
  id: number;
  label: string;
  options: CategoryOption[];
}

/**
 * Descripción: Este modal se ecarga de almacenar la logica para actualizar la oferta
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const UpdateOffersForm = ({
  theme,
  errors,
  control,
  register,
  actualData,
  updateOffer,
  handleClick,
  handleSubmit,
}: Props): JSX.Element => {
  // Estados
  const [statusSelected, setStatusSelected] = useState<boolean>(actualData.active);
  const [groupedOptions, setGroupedOptions] = useState<GroupedCategoryOption[]>([]);
  const [categoriesSelected, setCategoriesSelected] = useState<CategoryOption[]>([]);

  const [contentData, setContentData] = useState<string | null | undefined>(null);
  // Contextos
  const parentCategories = useParentCategories();
  const categories = useCategories();

  // Hooks

  // Funciones
  const endDateUpdate = register('updateEnd_date');

  const titleUpdate = register('updateTitle');

  const shortDescriptionUpdate = register('updateShort_description');

  const contentUpdate = register('updateContent');

  const statusOptions = [
    { value: true, label: '🟢 Activo' },
    { value: false, label: '🔴 Desactivado' },
  ];

  const formatGroupLabel = (data: any = groupedOptions): JSX.Element => (
    <div className={`groupselect ${theme}`}>
      <span className='groupselect__label'>{data.label}</span>
      <span className='groupselect__badge'>{data.options.length}</span>
    </div>
  );

  const onSubmit = (data: FieldValues): void => {
    const { updateEnd_date, updateTitle, updateShort_description } = data;

    const newData = {
      active: statusSelected,
      end_date: updateEnd_date,
      categories: categoriesSelected.map((item) => item.value),
      title: updateTitle || actualData.title,
      short_description: updateShort_description || actualData.short_description,
      content: contentData || actualData.content,
    };
    updateOffer(actualData.id, JSON.stringify(newData));
    sessionStorage.setItem('updateCategories', 'true');
  };

  // UseEffects
  useEffect(() => {
    if (categories.isSuccess) {
      groupedOptions.forEach((parent: Record<string, any>) => {
        categories.data?.data
          .filter((categorie: { parent: number }) => categorie.parent === parent.id)
          .forEach((categorie: { id: number; name: string }) =>
            parent.options.push({ value: categorie.id, label: categorie.name })
          );
      });
    }

    if (actualData.categories) {
      const { categories } = actualData;
      const selectedValues = categories
        .filter((categorie) => categorie.parent)
        .map((item: { id: number; name: string }) => ({
          value: item.id,
          label: item.name,
        }));
      setCategoriesSelected(selectedValues);
    }
  }, [groupedOptions, categories.isSuccess]);

  useEffect(() => {
    if (parentCategories.isSuccess) {
      const newGroupedOptions = parentCategories.data?.data.map(
        (item: { id: number; name: string }) => ({
          id: item.id,
          label: item.name,
          options: [],
        })
      );
      setGroupedOptions(newGroupedOptions);
    }
  }, [parentCategories.isSuccess]);

  // Renders
  return (
    <form className={`offersmodal ${theme}`} onSubmit={handleSubmit(onSubmit)}>
      <div className='offersmodal__header'>
        <h2>Actualizar Oferta:</h2>
        <button onClick={handleClick}>
          <AiOutlineCloseCircle />
        </button>
      </div>

      {/* Información basica */}
      <div className='offersmodal__row'>
        <div className='offersmodal__column'>
          <h3>Información básica:</h3>
          <p>Escriba la información básica sobre la oferta.</p>
        </div>

        <div className='offersmodal__form'>
          <div className='offersmodal__form__row'>
            <p>
              Estado:
              <Controller
                control={control}
                name='updateActive'
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    isSearchable={false}
                    options={statusOptions}
                    classNamePrefix='offersmodal__select'
                    className={`offersmodal__select ${theme}`}
                    loadingMessage={({ inputValue }) => 'Loading Message...'} // Provide your custom loading message here
                    onChange={(newValue: any): void => {
                      onChange(newValue.value);
                      setStatusSelected(newValue.value); // newValue.value: boolean / newValue: {value: boolean; label: string}
                    }}
                    value={statusOptions.find((option) => option.value === value) || null}
                  />
                )}
              />
            </p>

            <p>
              Fecha de finalización:
              <input
                type='date'
                name='updateEnd_date'
                min={actualDate()}
                defaultValue={formatInputDate(actualData.end_date, true)}
                className={errors.endDateUpdate ? 'error' : ''}
                {...endDateUpdate}
              />
              {errors.endDateUpdate && (
                <span role='alert'>{errors.endDateUpdate?.message}</span>
              )}
            </p>
          </div>

          <p>
            Categorias:
            <Controller
              name='updateCategories'
              control={control}
              render={({ field }) => (
                <ReactSelect
                  isMulti
                  {...field}
                  isSearchable={false}
                  options={groupedOptions}
                  value={categoriesSelected}
                  loadingMessage={({ inputValue }) => 'Loading Message...'} // Provide your custom loading message here
                  defaultValue={categoriesSelected}
                  formatGroupLabel={formatGroupLabel}
                  classNamePrefix='offersmodal__select'
                  className={`offersmodal__select ${theme}`}
                  onChange={(val: any) => {
                    setCategoriesSelected(val);
                    console.log(val);
                    // val: [{value: number; label: string}]
                  }}
                />
              )}
            />
            {errors.updateCategories && (
              <span role='alert'>{errors.updateCategories?.message}</span>
            )}
          </p>

          <p>
            Título de la oferta:
            <input
              {...titleUpdate}
              className={errors.updateTitle ? 'error' : ''}
              type='text'
              name='updateTitle'
              defaultValue={actualData.title}
              placeholder={actualData.title}
            />
            {errors.updateTitle && (
              <span role='alert'>{errors.updateTitle?.message}</span>
            )}
          </p>
        </div>
      </div>

      {/* Descripcion de la oferta */}
      <div className='offersmodal__row'>
        <div className='offersmodal__column'>
          <h3>Descripción de la oferta:</h3>
          <p>
            Escriba una descripción resumida y otra más completa con lo que usted
            considere importante.
          </p>
        </div>

        <div className='offersmodal__form'>
          <p>
            Descripción general:
            <textarea
              {...shortDescriptionUpdate}
              rows='10'
              type='text'
              name='updateShort_description'
              defaultValue={actualData.short_description}
              placeholder={actualData.short_description}
              className={errors.shortDescriptionUpdate ? 'error' : ''}
            />
            {errors.shortDescriptionUpdate && (
              <span role='alert'>{errors.shortDescriptionUpdate?.message}</span>
            )}
          </p>

          <p>
            Requerimientos:
            <Markdown
              theme={theme}
              prevContent={actualData.content}
              setContentData={setContentData}
              contentUpdate={contentUpdate}
            />
            {errors.updateContent && (
              <span role='alert'>{errors.updateContent?.message}</span>
            )}
          </p>
        </div>
      </div>

      {/* Footer */}
      <div className='offersmodal__footer'>
        <button onClick={handleClick}>Cancelar</button>
        <button type='submit'>Publicar</button>
      </div>
    </form>
  );
};

export default UpdateOffersForm;
