import { Link } from 'react-router-dom';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { useMsal } from '@azure/msal-react';
import Img from 'react-cool-img';
import { useUser } from '../../hooks/useUser';
import iconByArea from '../../helpers/IconByArea';
import ImgGenerator from '../../helpers/ImgGenerator';
import LoaderImage from '../../assets/images/static/loader.gif';
import ErrorImage from '../../assets/images/icons/general__icon.png';
import { type SidebarContextType } from '../../types/SidebarContext';
import { type ThemeContextType } from '../../types/ThemeContext';
import SidebarContext from '../../context/SidebarContext';
import { useContext } from 'react';

interface Props extends SidebarContextType {
  logOut: () => void;
  userLoggedIn: string | null;
}
interface Offer {
  id: number;
  title: string;
  categories: Category[];
}

interface Category {
  name: string;
}
interface Comment {
  id: number;
  applicant: Applicant;
  comment: string;
}

interface Applicant {
  id: number;
  names: string;
  last_names: string;
}
type PartialProps = Props & Pick<ThemeContextType, 'theme'>;

const Sidebar = ({
  theme,
  userId,
  setUser,
  sideOpen,
  toggleSide,
  userLoggedIn,
}: PartialProps): JSX.Element => {
  const { data, isError, isLoading, isSuccess } = useUser(userId ?? userLoggedIn);
  const { instance } = useMsal();
  const handleClose = (): void => {
    toggleSide(false);
    setTimeout(() => {
      setUser(null);
    }, 500);
  };

  const { userAvatarImg, firstName, lastName } = useContext(SidebarContext);
  // const { userAvatarImg, firstName, lastName, setFirstName, setLastName, setUserAvatarImg } = useContext(SidebarContext);

  const handleLogoutRedirect = (): void => {
    instance.logoutRedirect().catch((error): void => {
      console.log(error);
    });
    sessionStorage.clear();
  };

  return (
    <>
      <aside className={`profile ${theme} ${!sideOpen && 'profile__closed'}`}>
        <button title='profile close' onClick={handleClose} className='profile__close'>
          <IoCloseCircleOutline />
        </button>

        {isLoading && (
          <>
            <div className='profile__header'>
              <Img src={LoaderImage} alt='user photo profile' />

              <h2>Cargando..</h2>
              <p>-</p>
              <small>-</small>
            </div>

            <div className='profile__container'>
              <h3>Comentarios</h3>
              <ul>
                <li className='profile__comment'>
                  <Img src={LoaderImage} alt='user photo profile' />

                  <div className='profile__comment__text'>
                    <a href='#'>Cargando...</a>
                    <span>-</span>
                  </div>
                </li>
              </ul>

              <h3>Ofertas creadas</h3>
              <ul>
                <li className='profile__offers'>
                  <Img cache={false} src={LoaderImage} alt='user photo profile' />

                  <div className='profile__offers__text'>
                    <a href='#'>Cargando...</a>
                    <span>-</span>
                  </div>
                </li>
              </ul>
            </div>
          </>
        )}

        {isError && (
          <>
            <div className='profile__header'>
              <Img error={ErrorImage} alt='user photo profile' />

              <h2>Error</h2>
              <p>-</p>
              <small>-</small>
            </div>

            <div className='profile__container'>
              <h3>Comentarios</h3>
              <ul>
                <li className='profile__comment'>
                  <Img src={ErrorImage} alt='user photo profile' />

                  <div className='profile__comment__text'>
                    <a href='#'>No hay comentarios.</a>
                    <span>Todos los comentarios apareceran aqui.</span>
                  </div>
                </li>
              </ul>

              <h3>Ofertas creadas</h3>
              <ul>
                <li className='profile__comment'>
                  <Img src={ErrorImage} alt='user photo profile' />

                  <div className='profile__comment__text'>
                    <a href='#'>No hay Ofertas.</a>
                    <span>Todos las ofertas apareceran aqui.</span>
                  </div>
                </li>
              </ul>
            </div>
          </>
        )}

        {isSuccess && (
          <>
            <div className='profile__header'>
              <Img
                cache={false}
                src={userAvatarImg === undefined ? data.avatar : userAvatarImg}
                error={ErrorImage}
                placeholder={LoaderImage}
                alt='user photo profile'
              />
              <h2>{`${firstName === undefined ? data.firtsName : firstName} ${
                lastName === undefined ? data.lastName : lastName
              }`}</h2>
              <p>{data.job}</p>
              <small>{data.email}</small>
            </div>

            {data.id === Number(userLoggedIn) && (
              <div className='profile__actions'>
                <Link
                  to='/settings/account'
                  onClick={() => {
                    toggleSide(false);
                  }}
                >
                  Editar perfil
                </Link>
                <button onClick={handleLogoutRedirect}>Cerrar sesión</button>
              </div>
            )}

            <div className='profile__container'>
              <h3>Comentarios</h3>
              <ul>
                {data.comments.map((comment: Comment) => (
                  <li key={comment.id} className='profile__comment'>
                    <img
                      src={ImgGenerator(
                        `${comment.applicant.names.toUpperCase()} ${comment.applicant.last_names.toUpperCase()}`
                      )}
                      alt={`${comment.applicant.names} ${comment.applicant.last_names}`}
                      title={`${comment.applicant.names} ${comment.applicant.last_names}`}
                    />

                    <div className='profile__comment__text'>
                      <Link to={`applicants/${comment.applicant.id}`}>
                        {`${`${comment.applicant.names} ${comment.applicant.last_names}`.slice(
                          0,
                          20
                        )}...`}
                      </Link>
                      <span>{`${comment.comment}`}</span>
                    </div>
                  </li>
                ))}

                {data.comments.length === 0 && (
                  <li className='profile__comment'>
                    <Img src={ErrorImage} alt='user photo profile' />

                    <div className='profile__comment__text'>
                      <a href='#'>No hay comentarios.</a>
                      <span>Todos los comentarios apareceran aqui.</span>
                    </div>
                  </li>
                )}
              </ul>

              <h3>Ofertas creadas</h3>
              <ul>
                {data.offer.map((offer: Offer) => (
                  <li key={offer.id} className='profile__offers'>
                    <img src={iconByArea(offer.categories[0]?.name)} alt='' />

                    <div className='profile__offers__text'>
                      <Link to={`offers/all/details/${offer.id}`}>
                        {`${
                          offer.title.slice(0, 18).length === 18
                            ? `${offer.title.slice(0, 18)}...`
                            : `${offer.title.slice(0, 18)}`
                        }`}
                      </Link>
                      <span>6 aplicantes ~ 6 de Julio</span>
                    </div>
                  </li>
                ))}

                {data.offer.length === 0 && (
                  <li className='profile__comment'>
                    <Img src={ErrorImage} alt='user photo profile' />

                    <div className='profile__comment__text'>
                      <a href='#'>No hay Ofertas.</a>
                      <span>Todos las ofertas apareceran aqui.</span>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </>
        )}
      </aside>
    </>
  );
};

export default Sidebar;
