/* eslint-disable @typescript-eslint/strict-boolean-expressions */
// Librerias
import React, { useState, useEffect } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
// Contextos

// Hooks

// Componentes
import Comments from '../Applicants/Comments';

// Imagenes

// Estilos

// Types
import { type ThemeContextType } from '../../types/ThemeContext';

interface Props {
  theme: ThemeContextType['theme'];
  newComment: { data?: string[]; isLoading?: boolean };
  session: { id: number | string };
  refetch: () => Promise<void>;
  applicantSelected: ApplicantSelected | null;
  createComment: (body: Comment) => Promise<void>;
}
interface ApplicantSelected {
  id?: number | string;
  names?: string;
  last_names?: string;
  email?: string;
  country_code?: number;
  phone?: number;
  country?: string;
  state?: string;
  salary_expectations?: number;
  currency?: string;
  portfolio?: string | undefined;
  cv?: string | undefined;
  another_file?: string | undefined;
  aditional_links?: string;
  comments?: string[];
}
interface Comment {
  comment: string;
  userId: string | number;
  applicantId: string | number | undefined;
}
/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const OffersApplicantsColumn = ({
  theme,
  refetch,
  session,
  newComment,
  createComment,
  applicantSelected,
}: Props): JSX.Element => {
  // Estados
  const [comments, setComments] = useState<string>('');

  // Contextos

  // Hooks

  // Funciones
  const handleSubmit = (e: { preventDefault: () => void }): void => {
    e.preventDefault();
    void createComment({
      comment: comments,
      userId: session.id,
      applicantId: applicantSelected?.id,
    });
  };

  // UseEffects
  useEffect(() => {
    if (newComment.data) {
      setComments('');
    }
  }, [newComment]);

  // Renders
  return (
    <>
      <div className={`aplicant ${theme}`}>
        <h2>
          {applicantSelected?.names} {applicantSelected?.last_names}
        </h2>
        <hr />

        <div className='aplicant__container'>
          <h3>Información personal:</h3>
          <p>
            <strong>Correo:</strong> {applicantSelected?.email}
          </p>
          <p>
            <strong>Teléfono:</strong> {applicantSelected?.country_code}{' '}
            {applicantSelected?.phone}
          </p>
          <p>
            <strong>País:</strong> {applicantSelected?.country || '-'}
          </p>
          <p>
            <strong>Provincia:</strong> {applicantSelected?.state || '-'}
          </p>
          <p>
            <strong>Remuneracion pretendida:</strong>{' '}
            {applicantSelected?.salary_expectations
              ? `${applicantSelected?.salary_expectations} ${applicantSelected?.currency}`
              : '-'}
          </p>
        </div>

        <hr />
        <div className='aplicant__vacant'>
          <div className='aplicant__vacant__links'>
            {applicantSelected?.cv && (
              <a target='_blank' rel='noopener noreferrer' href={applicantSelected?.cv}>
                Currículum <BiLinkExternal />
              </a>
            )}

            {applicantSelected?.portfolio && (
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={applicantSelected?.portfolio}
              >
                Portafolio <BiLinkExternal />
              </a>
            )}

            {applicantSelected?.another_file && (
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={applicantSelected?.another_file}
              >
                Archivo adicional <BiLinkExternal />
              </a>
            )}

            {applicantSelected?.aditional_links && (
              <>
                {applicantSelected?.aditional_links
                  .split(',')
                  .map((item: string, index: React.Key | null | undefined) => (
                    <a key={index} target='_blank' rel='noopener noreferrer' href={item}>
                      {item
                        .replace('https://', '')
                        .replace('www.', '')
                        .split('/')[0]
                        .slice(0, 10)}{' '}
                      <BiLinkExternal />
                    </a>
                  ))}
              </>
            )}
          </div>
        </div>
        <hr />

        <Comments
          refetch={refetch}
          session={session}
          comments={comments}
          setComments={setComments}
          handleSubmit={handleSubmit}
          isLoading={newComment.isLoading}
          data={applicantSelected?.comments}
        />
      </div>
    </>
  );
};

export default OffersApplicantsColumn;
