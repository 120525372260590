/* eslint-disable @typescript-eslint/strict-boolean-expressions */
// Librerias
import React, { type Dispatch, type SetStateAction, useContext, type Key } from 'react';
import { Link, useParams } from 'react-router-dom';

// Contextos
import ThemeContext from '../../context/ThemeContext';

// Imagenes
import ImgGenerator from '../../helpers/ImgGenerator';
import { TfiCommentsSmiley } from 'react-icons/tfi';
// Estilos

// Types
export interface Props {
  setApplicantId: Dispatch<SetStateAction<string | number | null>>;
  applicants: Applicant[];
}
interface Applicant {
  id: number;
  active: boolean;
  names: string;
  last_names: string;
  email: string;
  offer?: {
    title: string;
  };
  categories?: Array<{
    parent: number;
    name: string;
  }>;
  comments?: string[];
  updatedAt?: string;
}

/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const ApplicantsItems = ({ setApplicantId, applicants }: Props): JSX.Element => {
  // Estados
  const params = useParams();

  // Contextos
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <div className={`aplicantslist__list ${theme}`}>
        {applicants.length ? (
          applicants.map((applicant: Applicant, index: Key) => (
            <Link
              to={`/applicants/${applicant.id}`}
              key={index}
              className={`item ${
                applicant?.id === Number(params.applicantId) && 'item__active'
              }`}
              onClick={() => {
                setApplicantId(applicant.id);
              }}
            >
              <img
                src={ImgGenerator(
                  `${applicant.names.toUpperCase()} ${applicant.last_names.toUpperCase()}`
                )}
                alt=''
              />
              <div className='item__text'>
                <h3>{`${applicant.names} ${applicant.last_names}`}</h3>
                <p> {applicant.offer ? applicant.offer.title : 'Sin búsqueda'}</p>

                {applicant.categories?.find(
                  (cat: { parent: number }) => cat.parent === 3
                ) && (
                  <p>
                    {
                      applicant.categories.find(
                        (cat: { parent: number }) => cat.parent === 3
                      )?.name
                    }
                  </p>
                )}
              </div>
              <p className='comments'>
                <TfiCommentsSmiley size={18} />
                <span>{applicant.comments?.length ? applicant.comments.length : 0}</span>
              </p>
              <p className='date'>
                {/* {new Date(applicant.updatedAt).toISOString().substring(0, 10)}{' '} */}
                {applicant.updatedAt
                  ? new Date(applicant.updatedAt).toISOString().substring(0, 10)
                  : ''}
              </p>
            </Link>
          ))
        ) : (
          <p className='empty'>No hay postulantes que coincidan con estos filtros</p>
        )}
      </div>
    </>
  );
};

export default ApplicantsItems;
