/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-void */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import {
  type Control,
  Controller,
  type FieldErrors,
  type UseFormHandleSubmit,
  type UseFormRegister,
  type UseFormUnregister,
} from 'react-hook-form';
import { AiOutlineCloseCircle } from 'react-icons/ai';

// Contextos

// Hooks
import { useParentCategories } from '../../../hooks/useCategories';

// Componentes
import CreateSlug from '../../../helpers/CreateSlug';
import { type ThemeContextType } from '../../../types/ThemeContext';

// Imagenes

// Estilos

// Tipos
interface Props {
  theme: ThemeContextType['theme'];
  firtsColumn: number;
  createCategories: (data: string) => void;
  handleClick: () => void;
  errors: FieldErrors<{ parent: number; name: string; slug: string }>;
  control: Control<{ parent: number; name: string; slug: string }>;
  register: UseFormRegister<{ parent: number; name: string; slug: string }>;
  unregister: UseFormUnregister<{ parent: number; name: string; slug: string }>;
  handleSubmit: UseFormHandleSubmit<{ parent: number; name: string; slug: string }>;
}
interface ParentOptionInterface {
  value: string;
  label: string;
}
interface ParentCategory extends ParentOptionInterface {
  id: string;
  name: string;
}
interface SelectOption {
  value: string | number;
  label: string;
}

/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const CategoriesForm = ({
  theme,
  errors,
  control,
  register,
  unregister,
  firtsColumn,
  handleClick,
  handleSubmit,
  createCategories,
}: Props): JSX.Element => {
  // Estados
  const [parentSelected, setParentSelected] = useState<number>(firtsColumn);
  const [slugValue, setSlugValue] = useState<string>('');
  const [parentOptions, setParentOptions] = useState<ParentOptionInterface[]>([
    {
      value: '',
      label: 'Selecciona una categoria padre',
    },
  ]);

  // Contextos

  // Hooks
  const parentCategories = useParentCategories();

  // Funciones
  const nameRegistration = register('name', {
    required: `El titulo es requerido.`,
  });

  const slugRegistration = register('slug');

  const onSubmit = (data: { name: string }): void => {
    const newData = {
      parent: parentSelected || firtsColumn,
      name: data.name,
      slug: slugValue,
    };

    createCategories(JSON.stringify(newData));
    sessionStorage.setItem('updateCategories', 'true');
    setSlugValue('');
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setSlugValue(CreateSlug(e.target.value));
  };

  // UseEffects
  useEffect(() => {
    if (parentCategories.isSuccess) {
      const newParents = parentCategories.data?.data.map((item: ParentCategory) => ({
        value: item.id,
        label: item.name,
      }));
      setParentOptions(newParents);
    }
  }, [parentCategories.isSuccess]);

  useEffect(() => {
    return () => {
      setSlugValue('');
      unregister('parent');
      unregister('name');
      unregister('slug');
      setParentSelected(firtsColumn);
    };
  }, []);

  // Renders
  return (
    <form className={`categoriesmodal ${theme}`} onSubmit={handleSubmit(onSubmit)}>
      <div className='categoriesmodal__header'>
        <h2>Crear Categoría:</h2>
        <button onClick={handleClick}>
          <AiOutlineCloseCircle />
        </button>
      </div>

      {/* Información basica */}
      <div className='categoriesmodal__row'>
        <div className='categoriesmodal__column'>
          <h3>Información de la categoría:</h3>
          <p>
            Escriba la información básica sobre la subcategoría, esto se usa para
            categorizar las ofertas y demás.
          </p>
        </div>

        <div className='categoriesmodal__form'>
          <div className='categoriesmodal__form__row'>
            <div className='categoriesmodal__form__row-inputContainer'>
              <p>Categoría padre:</p>
              <Controller
                name='parent'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    isSearchable={false}
                    options={parentOptions}
                    loadingMessage={() => 'Loading Message...'} // Provide your custom loading message here
                    classNamePrefix='categoriesmodal__select'
                    className={`categoriesmodal__select ${theme}`}
                    onChange={(val: SelectOption | null) => {
                      if (val) {
                        onChange(val.value);
                        setParentSelected(Number(val.value));
                      }
                    }}
                    value={parentOptions.find((s: SelectOption) => s.value === parentSelected)}
                    // defaultValue={parentOptions.find(
                    //   (s: SelectOption) => s.value === firtsColumn
                    // )}
                  />
                )}
              />
            </div>
          </div>

          <div className='categoriesmodal__form__row-inputContainer'>
            Nombre:
            <input
              type='text'
              placeholder='Ej: Mantenimiento'
              className={errors.name != null ? 'error' : ''}
              {...nameRegistration}
              onChange={handleChange}
            />
            {errors.name != null && <span role='alert'>{errors.name?.message}</span>}
          </div>

          <div className='categoriesmodal__form__row-inputContainer'>
            Slug:
            <input
              readOnly
              disabled
              id='slug'
              type='text'
              value={slugValue}
              className={errors.slug != null ? 'error' : ''}
              placeholder='Se rellena automaticamente'
              {...slugRegistration}
            />
            {errors.slug != null && <span role='alert'>{errors.slug?.message}</span>}
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className='categoriesmodal__footer'>
        <button onClick={handleClick}>Cancelar</button>
        <button type='submit'>Publicar</button>
      </div>
    </form>
  );
};

export default CategoriesForm;
